import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "pl-10 pr-10 py-6 space-y-5 flex flex-col"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChapterHeadline = _resolveComponent("ChapterHeadline")!
  const _component_ParagraphList = _resolveComponent("ParagraphList")!
  const _component_VocabTile = _resolveComponent("VocabTile")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ChapterHeadline, {
            name: _ctx.chapter?.name,
            nextId: _ctx.chapter?.nextId,
            prevId: _ctx.chapter?.prevId
          }, null, 8, ["name", "nextId", "prevId"]),
          (_ctx.chapter?.paragraphs?.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chapter?.paragraphs, (list) => {
                  return (_openBlock(), _createBlock(_component_ParagraphList, {
                    key: list.id,
                    rtl: _ctx.rightToLeftLanguages.includes(list.id),
                    paragraphs: list.paragraphs
                  }, null, 8, ["rtl", "paragraphs"]))
                }), 128))
              ]))
            : (_openBlock(), _createBlock(_component_ion_list, { key: 1 }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chapter?.vocabs, (vocab) => {
                    return (_openBlock(), _createBlock(_component_VocabTile, {
                      icon: vocab.icon,
                      key: vocab.id,
                      id: vocab.id,
                      translations: vocab.translations
                    }, null, 8, ["icon", "id", "translations"]))
                  }), 128))
                ]),
                _: 1
              }))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}