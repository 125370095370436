import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cdad7a6c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["dir"]
const _hoisted_2 = { class: "text-xl text-primary font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "flex flex-col",
    dir: $props.rtl ? 'rtl' : 'ltr'
  }, [
    _createElementVNode("span", _hoisted_2, _toDisplayString($props.paragraphs[0]), 1),
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.paragraphs.slice(1), (paragraph, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: index,
          class: "mb-2 text-lg"
        }, _toDisplayString(paragraph), 1))
      }), 128))
    ])
  ], 8, _hoisted_1))
}