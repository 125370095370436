
import { IonIcon, IonFab, IonFabButton, useIonRouter } from '@ionic/vue'
import {
  star,
  arrowBackOutline,
  chevronBackCircleOutline,
  chevronForwardCircleOutline
} from 'ionicons/icons'

import { defineComponent } from 'vue'

export default defineComponent({
  components: { IonIcon, IonFab, IonFabButton },
  props: ['name', 'nextId', 'prevId'],
  setup() {
    const ionRouter = useIonRouter()
    return {
      ionRouter,
      star,
      arrowBackOutline,
      chevronBackCircleOutline,
      chevronForwardCircleOutline
    }
  }
})
