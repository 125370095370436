
import { IonPage, IonContent, IonList } from '@ionic/vue'
import VocabTile from '../components/VocabTile.vue'
import ParagraphList from '../components/ParagraphList.vue'
import ChapterHeadline from '../components/ChapterHeadline.vue'
import { computed, defineComponent, watch } from '@vue/runtime-core'
import { useRoute } from 'vue-router'
import { getChapter, getLanguages, getSportsClubParagraphs } from '../db'
import { ref } from 'vue'
import { settings } from '../settings'
import { getRightToLeftLanguages } from '../db'

export default defineComponent({
  name: 'ChapterDetail',
  components: {
    IonContent,
    IonPage,
    ParagraphList,
    VocabTile,
    ChapterHeadline,
    IonList
  },
  setup() {
    const route = useRoute()
    const chapterId = ref(parseInt(route.params.id as string))
    const languages = getLanguages()

    watch(
      () => route.params.id,
      (newId) => {
        const id = parseInt(newId as string)

        if (route.name === 'ChapterDetail' && id) {
          chapterId.value = id
        }
      }
    )

    const chapter = computed(() => {
      const result = getChapter(chapterId.value)

      if (!result) {
        return null
      }

      const { chapter, nextId, prevId, vocabs } = result

      let paragraphs: { id: string; paragraphs: string[] }[] = []

      if (chapter.id === 1) {
        paragraphs = [
          ...languages
            .filter(
              (l) =>
                l.id === settings.value.primaryLanguage ||
                settings.value.secondaryLanguages.includes(l.id)
            )
            .map((language) => ({
              id: language.id,
              paragraphs: getSportsClubParagraphs(language.id)
            }))
            // Primary language is always first
            .sort((a, b) => {
              if (a.id === settings.value.primaryLanguage) {
                return -1
              }

              if (b.id === settings.value.primaryLanguage) {
                return 1
              }

              return 0
            })
        ]
      }

      return {
        chapterId,
        name: chapter.translations[settings.value.primaryLanguage],
        nextId,
        prevId,
        vocabs,
        paragraphs
      }
    })

    return {
      chapter,
      rightToLeftLanguages: getRightToLeftLanguages()
    }
  }
})
