import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d0cd4d9e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex py-2" }
const _hoisted_2 = { class: "flex-none w-12 flex items-center justify-center" }
const _hoisted_3 = { class: "grow w-full min-w-0 p-2" }
const _hoisted_4 = { class: "text-lg font-semibold truncate" }
const _hoisted_5 = { class: "flex-none flex w-28 items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ion_fab, { horizontal: "begin" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_fab_button, {
            "router-link": "/alle-kapitel",
            "router-direction": "back",
            class: "p-0 fab-button",
            color: "--ion-color-white"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                icon: _ctx.arrowBackOutline,
                class: "w-6 h-6"
              }, null, 8, ["icon"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.name), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_ion_fab, { horizontal: "begin" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_fab_button, {
            "router-link": `/kapitel/${_ctx.prevId}`,
            "router-direction": "none",
            class: "p-0 fab-button",
            color: "--ion-color-white"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                icon: _ctx.chevronBackCircleOutline,
                class: "w-7 h-7"
              }, null, 8, ["icon"])
            ]),
            _: 1
          }, 8, ["router-link"])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_fab, { horizontal: "end" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_fab_button, {
            "router-link": `/kapitel/${_ctx.nextId}`,
            "router-direction": "none",
            class: "p-0 fab-button",
            color: "--ion-color-white"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                icon: _ctx.chevronForwardCircleOutline,
                class: "w-7 h-7"
              }, null, 8, ["icon"])
            ]),
            _: 1
          }, 8, ["router-link"])
        ]),
        _: 1
      })
    ])
  ]))
}